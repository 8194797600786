<script setup lang="ts">
const {
  meta: { linenBackground = false, containsFlushContent = false },
} = useRoute()

const contentClasses = computed(() => ({
  LayoutEmbedded: true,
  'LayoutEmbedded--LinenBackground': linenBackground,
  'LayoutEmbedded--Contain': !containsFlushContent,
}))
</script>

<template>
  <main data-test-id="content" :class="contentClasses">
    <slot />
  </main>
</template>
<style lang="scss">
.LayoutEmbedded {
  display: grid;
  min-height: 100vh;

  &--Contain {
    @include container-small;
    word-break: break-word;

    .SkyLink {
      /**
      * Some pages can contain very long links.
      * They should break to prevent horizontal scrolling.
      */
      word-wrap: break-word;
    }
  }

  &--LinenBackground {
    background-color: var(--surface-color-linen);
  }
}
</style>
